import React from 'react';
import './Footer.css';

const Footer = ()=>{
  return(
    <footer className="footer">&copy; Copyright 2021 All right reserved</footer>
  )
}

  
export default Footer;